<template>
    <vx-card title="Inter Warehouse">
        <div class="vx-input-group flex">
            <vs-tabs v-if="render" v-model="$route.params.status">
                <vs-tab label="Waiting Approval">
                    <tab-waiting-approval></tab-waiting-approval>
                </vs-tab>
                <!-- <vs-tab label="Inquiry">
                    <tab-inquiry></tab-inquiry>
                </vs-tab> -->
                <vs-tab label="Approved">
                    <tab-approved></tab-approved>
                </vs-tab>
                <vs-tab label="Rejected">
                    <tab-rejected></tab-rejected>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import TabWaitingApproval from "./TabWaitingApproval.vue";
import TabInquiry from "./TabInquiry.vue";
import TabApproved from "./TabApproved.vue";
import TabRejected from "./TabRejected.vue";
export default {
    components: {
        TabWaitingApproval,
        TabInquiry,
        TabApproved,
        TabRejected,
    },
    data() {
        return {
            activeTab: "Waiting",
            render: true,
        }
    },
    methods: {

    }
}
</script>